/*
  Ant d 를 제외 한 태그들만 reset css 적용,
  하위 태그에 영향이 가는 부분은 제외
  - 참고 [https://meyerweb.com/eric/tools/css/reset/]

  Todo : StyledComponent GlobalStyles : createGlobalStyle을 이용해서 생성
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video:not([class^='ant-']) {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

li,
ol,
ul:not([class^='ant-']) {
  list-style: none;
}

blockquote,
q:not([class^='ant-']) {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after:not([class^='ant-']) {
  content: '';
  content: none;
}

table:not([class^='ant-']) {
  border-collapse: collapse;
  border-spacing: 0;
}
