html {
  color: black;
  font-family: 'Pretendard,Apple SD Gothic Neo,"돋움",Dotum,Helvetica Neue,arial,sans-serif';
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.6;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-size: calc(min(100vw, 450px) / 375 * 10); /* 지원하는 최대사이즈를 450px로 지정 */
}

body {
  padding: 0;
  margin: auto;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

*,
::after,
::before {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  /* antd 의 이미지 placeholder 의 blur 효과 제거 */
  filter: blur(0px) !important;
}

.button {
  cursor: pointer;
}

.button:active {
  filter: brightness(90%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select:focus {
  outline-style: none;
}

/* CUSTOM - slick */
.center .slick-center {
  opacity: 1;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}
/* CUSTOM - slick */

/* CUSTOM - Antd */
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}

.ant-message-notice-content {
  padding: 10px 20px !important;
  background: #1b1e21 !important;
  border-radius: 8px !important;
  opacity: 0.9;
  color: #ffffff;
}

.ant-message {
  width: 100%;
  left: unset;
  right: unset;
}

.ant-modal-root {
  display: flex;
  justify-content: center;
}

.ant-modal-wrap {
  left: unset;
  right: unset;
}

.ant-message-info .anticon {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  color: #ff4d4f;
  content: '*';
  line-height: 1.2px;
  font-size: 14px;
  display: inline-block;
  margin-left: 4px;
}

.ant-tooltip-arrow-content {
  width: 10px !important;
  height: 10px !important;
}

.ant-form-item-explain-error {
  color: #f27272 !important;
}

.ant-form-item-has-error .ant-input-disabled.ant-input-borderless.ant-input,
.ant-form-item-has-error,
.ant-input-affix-wrapper-disabled.ant-input-affix-wrapper-borderless.ant-input-affix-wrapper,
.ant-form-item-has-error ant-input-disabled.ant-input-borderless.ant-input:hover,
.ant-form-item-has-error,
.ant-input-affix-wrapper-disabled.ant-input-affix-wrapper-borderless.ant-input-affix-wrapper:hover {
  border-color: #f27272 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: rgb(67, 67, 67);
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: rgb(67, 67, 67);
  box-shadow: none !important;
}

.ant-form-item-explain-error {
  margin-top: -3px;
  margin-bottom: 5px;
}
/* CUSTOM - Antd */

@media (min-width: 720px) {
  body {
    border: solid #efefef;
    border-width: 0 1px;
  }
  .ant-message {
    width: 720px;
  }
}

@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard Bold'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Bold.subset.woff2') format('woff2'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Bold.woff') format('woff'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Bold.ttf') format('truetype');
  font-display: block;
  font-weight: 700;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard Medium'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Medium.subset.woff2') format('woff2'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Medium.woff') format('woff'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Medium.ttf') format('truetype');
  font-display: block;
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard Regular'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Regular.subset.woff2') format('woff2'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Regular.woff') format('woff'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Regular.ttf') format('truetype');
  font-display: block;
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard Light'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Light.subset.woff2') format('woff2'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Light.woff') format('woff'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Light.ttf') format('truetype');
  font-display: block;
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard Thin'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Thin.subset.woff2') format('woff2'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Thin.woff') format('woff'),
    url('https://static.fitpetcdn.com/prod/assets/fonts/Pretendard-Thin.ttf') format('truetype');
  font-display: block;
  font-weight: 100;
}

.Bold {
  font-weight: 700;
}

.SemiBold {
  font-weight: 600;
}

.Medium {
  font-weight: 500;
}

.Regular {
  font-weight: 400;
}

.Heading1 {
  display: inline-block;
  font-size: 3rem;
  line-height: 4.2rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Heading2 {
  display: inline-block;
  font-size: 2.6rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Heading3 {
  display: inline-block;
  font-size: 2.3rem;
  line-height: 3.4rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Heading4 {
  display: inline-block;
  font-size: 2.2rem;
  line-height: 3.1rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Heading5 {
  display: inline-block;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Heading6 {
  display: inline-block;
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Body1 {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Body2 {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Body3 {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Body4 {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Body5 {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Caption1 {
  display: inline-block;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.Caption2 {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

